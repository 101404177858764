import { useContext, useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';

import { Cookie } from '../services';
import { INVALID_SESSION } from '../constants/StatusConstants';
import { UserContext } from '../context/UserContext';
import * as Utils from '../utils';
import { TerminationErrors } from './utility';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux';
import { oktaModalSliceActions } from '../redux/gtw-okta-modal-slice';

const TIME_BEFORE_SESSION_EXPIRES = 5 * 60 * 1000;

export const useRefreshSessions = () => {
  const userContext = useContext(UserContext);
  const dispatch = useAppDispatch();
  const timeoutModalConfig = useAppSelector((state: RootState) => state.gtwOktaModalSliceData.timeoutModalConfig);

  const [showSessionExpireDialog, setShowSessionExpireDialog] = useState<boolean>(false);

  const interval = useRef<Record<string, unknown>>({});

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      clearInterval(interval.current.timeout as NodeJS.Timeout);
      // clearInterval(interval.current.timeout2 as NodeJS.Timeout);
      setShowSessionExpireDialog(false);
      dispatch(oktaModalSliceActions.setTimeoutModalConfig({ isOpened: false, remindLater: false }));
    }
  }, [window.location.pathname]);

  const onKeepLogin = async (extendGatewaySession: boolean, extendOktaSession: boolean) => {
    setShowSessionExpireDialog(false);

    if (extendGatewaySession) {
      userContext.extendGatewaySession();
    }
    // if (extendOktaSession) {
    //   const res = await userContext.extendOktaSession();
    //   if (!res.success) {
    //     console.info('failed to refresh okta session');
    //     dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.FailedToRefreshOktaSession));
    //     Cookie.setMessageID(TerminationErrors.FailedToRefreshOktaSession);
    //     return showModals(false, undefined);
    //   }
    // }
  };

  const showModals = (remindLater: boolean, oktaSessionExpiresAt?: string) => {
    if (oktaSessionExpiresAt) {
      setShowSessionExpireDialog(true);
    } else if (!timeoutModalConfig.isOpened) {
      dispatch(oktaModalSliceActions.setTimeoutModalConfig({ isOpened: true, remindLater }));
    }
  };

  useEffect(() => {
    interval.current.timeout = setInterval(() => {
      if (window.location.pathname.startsWith('/auth/login')) {
        clearInterval(interval.current.timeout as NodeJS.Timeout);
        return;
      }
      if (showSessionExpireDialog || timeoutModalConfig.isOpened) {
        return;
      }

      const oktaTokenExpiresAt = Cookie.getOktaTokenExpiresAt();
      const oktaSessionExpiresAt = Cookie.getOktaSessionExpirationTime();
      const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();
      const sessionNotificationsCounter = Cookie.getSessionNotificationsCounter();

      if (oktaTokenExpiresAt === 'null') {
        console.info('okta token extend session limit is reached');
        dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.OktaTokenExtendSessionLimitReached));
        Cookie.setMessageID(TerminationErrors.OktaTokenExtendSessionLimitReached);
        return showModals(false, undefined);
      }

      if (oktaTokenExpiresAt && gatewaySessionExpiresAt) {
        const timeNow = new Date().getTime();
        const gatewaySessionDiffInMilliseconds = Number(gatewaySessionExpiresAt) - timeNow;

        if (gatewaySessionDiffInMilliseconds <= 0) {
          Cookie.setStatus(INVALID_SESSION);
          console.info('gateway session expired', gatewaySessionDiffInMilliseconds);

          return Utils.navigateToLogin();
        }

        if (
          (!sessionNotificationsCounter && gatewaySessionDiffInMilliseconds < TIME_BEFORE_SESSION_EXPIRES) ||
          gatewaySessionDiffInMilliseconds < 30 * 1000
        ) {
          const isRemindLaterBtnAvailable = gatewaySessionDiffInMilliseconds > 30 * 1000;
          if (!(isRemindLaterBtnAvailable && oktaSessionExpiresAt)) {
            dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.GatewaySessionExpired));
            Cookie.setMessageID(TerminationErrors.GatewaySessionExpired);
          }
          showModals(isRemindLaterBtnAvailable, oktaSessionExpiresAt);
        }
      }
    }, 10 * 1000);

    // interval.current.timeout2 = setInterval(
    //   () => {
    //     if (window.location.pathname.startsWith('/auth/login')) {
    //       clearInterval(interval.current.timeout2 as NodeJS.Timeout);
    //       return;
    //     }

    //     onKeepLogin(false, true);
    //   },
    //   25 * 60 * 1000,
    // );

    return () => {
      clearInterval(interval.current.timeout as NodeJS.Timeout);
      // clearInterval(interval.current.timeout2 as NodeJS.Timeout);
    };
  }, [timeoutModalConfig, showSessionExpireDialog]);

  return {
    showSessionExpireDialog,
    onKeepLogin,
  };
};
