import React, { useState, useEffect, useContext, useRef, useCallback, PropsWithChildren } from 'react';
import { navigate } from 'gatsby';
import { ToastContainer, toast, Id, ToastPosition } from 'react-toastify';
import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import { CognicorPopup } from '../components/Cognicor';
import SimpleLayout from './SimpleLayout';
import SidebarCustom from './Sidebar';
import { AuthContext } from '../context/AuthContext';
import { getCurrentRole } from '../services/GatewayManagement';
import { FullScreenLoader, UserContext } from '../context/UserContext';
import { ShadowUserOverlay } from './shadowUserOverlay';
import { setFavicon } from '../components/Auth/utility/utilityFunctions';
import { EnrollmentAPI, Cookie } from '../services';
import { useRefreshSessions } from './useRefreshSessions';
import * as Utils from '../utils';

import { OktaSessionModal } from './OktaSessionModal';
import { RefreshSessionPopup } from './RefreshSessionPopup';
import { SocketContext, SocketEvents } from '../context/SocketContext';
import { MessageTypes, RoleType } from '../constants/Types';

/**
 * Experimental functionality, will be removed in future.
 */
import { useStewardCustomRedirect } from "../hooks/experimental/useStewardCustomRedirect";

const SimpleStyle = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  .layout-container {
    height: 100vh;
  }
`;
const ParentDiv = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;

  .main-container {
    flex: 1;
  }
`;

const toastOptions = {
  position: 'bottom-right' as ToastPosition,
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const refreshPeriod = 3 * 60 * 1000;

const LayoutPage: React.FC<PropsWithChildren> = ({ children }) => {
  /**
   * Experimental functionality, will be removed in future.
   */
  useEffect(useStewardCustomRedirect,[])

  const [dir] = useState<'ltr' | 'rtl'>('ltr');
  const [background, setBackground] = useState<string>('');
  const [isLogOutCase, setIsLogOutCase] = useState(false);
  const [roleRes, setRoleRes] = useState<boolean | undefined>(undefined);
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const { socket } = useContext(SocketContext);

  const { showSessionExpireDialog, onKeepLogin } = useRefreshSessions();

  const errorsArray = useRef<Id[] | null>(null);

  const activateLoader = useCallback(() => {
    const isLoaderActive = sessionStorage.getItem('logoutLoaderActive');
    setIsLogOutCase(Boolean(isLoaderActive === 'true'));
  }, []);

  useEffect(() => {
    window.addEventListener('storage', activateLoader);
  }, []);

  useEffect(() => {
    const event = SocketEvents.OwnRoleRefresh;

    if (socket) {
      socket.on(event, (response: Partial<RoleType>) => {
        userContext.setRole({ ...response, success: true });
        userContext.setToastifySettings({
          type: MessageTypes.Info,
          msg: 'Your role was updated by administrator, please reload your application.',
          options: { autoClose: 10000, closeOnClick: false },
        });
      });
    }

    return () => {
      if (socket) {
        socket.off(event);
      }
    };
  }, [socket]);

  useEffect(() => {
    toastifyBuilderAndLauncher(
      userContext.toastifySettings.type,
      userContext.toastifySettings.msg,
      userContext.toastifySettings?.options,
    );
  }, [userContext.toastifySettings]);

  const toastifyBuilderAndLauncher = (
    type: string,
    msg: string,
    additionalOptions: { autoClose?: number; closeOnClick?: boolean; icon?: JSX.Element } = {},
  ) => {
    switch (type) {
      case 'success':
        toast.success(msg, { ...toastOptions, ...additionalOptions });
        break;
      case 'error':
        const newErrorId = toast.error(msg, { ...toastOptions, ...additionalOptions });
        if (errorsArray.current) {
          errorsArray.current.push(newErrorId);
        } else {
          errorsArray.current = [newErrorId];
        }
        break;
      case 'info':
        toast.info(msg, { ...toastOptions, ...additionalOptions });
        break;
    }
  };

  const getInfo = async () => {
    const roleResData = await getCurrentRole();
    setRoleRes(roleResData.success);
  };

  useEffect(() => {
    if (window.location.href.includes('/auth/login')) {
      sessionStorage.removeItem('logoutLoaderActive');
      setIsLogOutCase(false);
      setRoleRes(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    handleRedirectForValidSession();
    getInfo();
    setFavicon(authContext);
  }, []);

  useEffect(() => {
    checkDownForMaintenanceState();
  }, [window.location.href]);

  useEffect(() => {
    if (window.location.href.match(/invalid_request/)) localStorage.setItem('invalid-request', 'true');
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes('/dashboard/') || window.location.pathname.includes('/extra-components/')) {
      setBackground('dashboard');
    }
  }, []);

  const checkDownForMaintenanceState = useCallback(async () => {
    const isServerDown = await EnrollmentAPI.getDownForMaintenanceState();

    if (isServerDown.technicalWorks && !window.location.pathname.includes('down-for-maintenance')) {
      navigate('/down-for-maintenance');
    } else if (!isServerDown.technicalWorks && window.location.pathname.includes('down-for-maintenance')) {
      navigate('/');
    }

    setTimeout(() => checkDownForMaintenanceState(), refreshPeriod);
  }, []);

  const handleRedirectForValidSession = () => {
    const accessToken = Cookie.getAccessToken();
    if (accessToken && window.location.pathname.includes('/auth/login')) {
      navigate('/');
    }
  };

  const isAuthPageCase = !isLogOutCase && roleRes === false;
  const isSuccessfullLoginCase = !isLogOutCase && roleRes;

  return (
    <SimpleLayout dir={dir}>
      {isLogOutCase && <FullScreenLoader customText="Just a moment while we are logging you out..." />}
      {isAuthPageCase && (
        <div className="auth-layout">
          <div style={{ backgroundColor: authContext.customBgColor.color }}>
            <div className={`auth-outer-container ` + background}>
              <div className="main-content">{children}</div>
            </div>
          </div>
        </div>
      )}
      {isSuccessfullLoginCase && (
        <ParentDiv>
          <div className="main-container">
            {userContext.shadowedRole?.isShadowed ? <ShadowUserOverlay /> : null}
            <SimpleStyle>
              <div className="layout-container">
                <SidebarCustom>{children}</SidebarCustom>
              </div>
            </SimpleStyle>
          </div>
          <CognicorPopup />
        </ParentDiv>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        style={{ width: 'auto', maxWidth: '700px', zIndex: 99999999 }}
        draggable
        pauseOnHover
        theme="colored"
        limit={10}
      />
      {showSessionExpireDialog && (
        <RefreshSessionPopup
          showSessionExpireDialog={showSessionExpireDialog}
          handleLogout={() => Utils.navigateToLogin()}
          handleKeepLogin={() => {
            onKeepLogin(true, false);
          }}
        />
      )}
      <OktaSessionModal />
    </SimpleLayout>
  );
};

export default LayoutPage;
