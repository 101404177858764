import axios from 'axios';

import { Cookie } from '..';
import { ITeams, ITeamUpdatePayload } from '../../constants/AdvisoryTeamsConstants';
import { getFailedApiCallResponse } from '../utils';

export const getAdvisoryTeams = async (): Promise<{
  success: boolean;
  data?: ITeams[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/teams', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteAdvisoryTeam = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.delete(`/gateway/teams/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateAdvisoryTeam = async (
  id: string,
  team: ITeamUpdatePayload,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.patch(`/gateway/teams/${id}`, team, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const createAdvisoryTeam = async (
  team: ITeamUpdatePayload,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post('/gateway/teams', team, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 201) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const syncAdvisoryTeams = async (): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post(
      '/gateway/teams/sync-advisory-team',
      {},
      {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      },
    );

    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getAdvisoryTeamsStatus = async (): Promise<{
  success: boolean;
  status?: boolean;
  queued?: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get(`/gateway/teams/sync-advisory-team/status`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200 && res.data) {
      return { success: true, status: res.data?.working, queued: res.data?.queued || false };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
