import axios from 'axios';

import Cookie from '../Cookie';
import { filterSupportedMFAHandler } from '../../utils';
import { getFailedApiCallResponse } from '../utils';
import {
  IAssignedMfas,
  ISupportedMfaMethods,
  IMfasState,
  ISaveMfaPayload,
  ISaveMfaOktaResponse,
  MfaStatusEnum,
  IVerifyCodePayload,
} from '../../constants/MfaConstants';

export const getCurrentMfaMethods = async (): Promise<{
  success: boolean;
  data?: IMfasState[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get<IAssignedMfas[]>('/gateway/users/mfa', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      const supportedMFA = filterSupportedMFAHandler<IAssignedMfas>(res.data);

      const mappedResponse = (supportedMFA ?? []).map(({ factorType, provider, profile, status, id }) => ({
        factorType,
        provider,
        profile,
        isUnderEdit: false,
        isNew: false,
        status,
        id,
      }));

      return {
        success: true,
        data: mappedResponse,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSupportedMfaMethods = async (): Promise<{
  success: boolean;
  data?: ISupportedMfaMethods[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get<ISupportedMfaMethods[]>('/gateway/users/supported-mfa', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      const supportedMFA = filterSupportedMFAHandler<ISupportedMfaMethods>(res.data);

      return {
        success: true,
        data: supportedMFA ?? [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateMfaMethod = async (
  id: string,
  mfa: ISaveMfaPayload,
): Promise<{
  success: boolean;
  data?: ISaveMfaOktaResponse;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  const payload = {
    factorType: mfa.factorType,
    provider: mfa.provider,
    profile: mfa.profile,
  };
  try {
    const res = await axios.put(`/gateway/users/mfa/${id}`, payload, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const saveMfaMethod = async (
  mfa: ISaveMfaPayload,
): Promise<{
  success: boolean;
  data?: ISaveMfaOktaResponse;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post('/gateway/users/mfa/enroll', mfa, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteMfa = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.delete<ISaveMfaOktaResponse>(`/gateway/users/mfa/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const verifyCode = async (
  data: IVerifyCodePayload,
): Promise<{
  success: boolean;
  status?: MfaStatusEnum;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post('/gateway/users/mfa/verify', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        status: res.data.status,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const activatePushMFAMethod = async (
  url: string,
  payload: {
    factorId: string;
    userId: string;
  },
): Promise<{
  success: boolean;
  status?: MfaStatusEnum;
  error?: string;
}> => {
  try {
    const res = await axios.post(url, payload, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    });
    if (res.status === 200) {
      return {
        success: true,
        status: res.data.status,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
