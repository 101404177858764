import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Button, Modal } from '@mui/material';
import Fade from '@mui/material/Fade';

import { oktaSessionModalStyles } from './styles/oktaSessionModal.styles';
import { convertSecondsToText, getSessionExpireCountDownTime } from './utility';
import { Cookie } from '../services';
import { INVALID_SESSION } from '../constants/StatusConstants';
import * as Utils from '../utils';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { RootState } from '../redux';
import { oktaModalSliceActions } from '../redux/gtw-okta-modal-slice';

export const OktaSessionModal = () => {
  const dispatch = useAppDispatch();

  const timeoutModalConfig = useAppSelector((state: RootState) => state.gtwOktaModalSliceData.timeoutModalConfig);
  const terminationCode = useAppSelector((state: RootState) => state.gtwOktaModalSliceData.terminationCode);

  const btnRef = useRef(null);

  let modalInterval: ReturnType<typeof setInterval>;

  const [countDownTimer, setCountDownTimer] = useState<number | null>(getSessionExpireCountDownTime());

  useEffect(() => {
    const oktaTokenExpiresAt = Cookie.getOktaTokenExpiresAt();

    if (oktaTokenExpiresAt === 'null' || terminationCode) {
      setCountDownTimer(15);
    }
  }, [timeoutModalConfig.isOpened, terminationCode]);

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      clearInterval(modalInterval);
      return;
    }

    if (!timeoutModalConfig.isOpened) {
      return;
    }

    modalInterval = setInterval(() => {
      if (typeof countDownTimer === 'number') {
        if (countDownTimer > 0) {
          const newCountDownTimer = countDownTimer - 1;
          setCountDownTimer(newCountDownTimer);
        } else {
          clearInterval(modalInterval);
          Cookie.setStatus(INVALID_SESSION);
          Utils.navigateToLogin();
        }
      }
    }, 1000);

    return () => clearInterval(modalInterval);
  }, [countDownTimer, timeoutModalConfig.isOpened]);

  useEffect(() => {
    if (btnRef.current) {
      animateBtn();
    }
  }, [btnRef.current]);

  const animateBtn = () => {
    const btnElement = btnRef.current as unknown as HTMLButtonElement;

    if (btnElement) {
      btnElement.style.backgroundPosition = 'right';
    }
  };

  const handleRemindLaterClick = () => {
    dispatch(oktaModalSliceActions.setTimeoutModalConfig({ isOpened: false, remindLater: false }));

    const sessionNotificationsCounter = Cookie.getSessionNotificationsCounter();
    Cookie.setSessionNotificationsCounter(String(Number(sessionNotificationsCounter ?? 0) + 1));
  };

  return (
    <Modal open={timeoutModalConfig.isOpened} sx={oktaSessionModalStyles.modal}>
      <Fade in={timeoutModalConfig.isOpened}>
        <Card sx={oktaSessionModalStyles.root}>
          <CardContent sx={oktaSessionModalStyles.content}>
            <Typography sx={oktaSessionModalStyles.title}>You're about to be signed out</Typography>
            <Typography sx={oktaSessionModalStyles.modalText}>
              Your organization's security policy enforces automatic sign out after a period of time based on your
              browser settings.
            </Typography>
            <Typography sx={oktaSessionModalStyles.modalText}>
              You will be redirected to log in page in {convertSecondsToText(countDownTimer)}.
            </Typography>
            <Typography sx={oktaSessionModalStyles.terminationCode}>Message ID: {terminationCode}</Typography>
            <Button
              ref={btnRef}
              variant="contained"
              sx={oktaSessionModalStyles.btns}
              onClick={() => Utils.navigateToLogin()}
              style={{ transition: `all ${Number(countDownTimer)}s linear` }}
            >
              Log out now
            </Button>
            {timeoutModalConfig.remindLater && (
              <Button variant="contained" sx={oktaSessionModalStyles.btns} onClick={() => handleRemindLaterClick()}>
                Remind me later
              </Button>
            )}
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};
